.header {
  position: sticky;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 0;
}

.header.scrolled {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.21);
  background-color: var(--fill-color-contrast);
}

@media (max-width: 991px) {
  .desktopItems {
    display: none;
  }
}
