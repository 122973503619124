.featuresItem {
  position: relative;
  overflow: hidden;
}

.featuresItem.background {
  background-color: var(--color-grey-grey2-100);
}

.featuresItemInner {
  display: flex;
  gap: 32px;
  padding-top: 50px;
}

.featuresItemInner.reverse {
  flex-direction: row-reverse;
}

.featuresItemIconWrap {
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  background: var(--color-primary-75);
  width: max-content;
  padding: 12px;
}

.featuresItemContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  flex: 1 1;
}

.featuresItemImageBlock {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 991px) {
  .featuresItemInner {
    flex-direction: column;
    padding-top: 64px;
  }

  .featuresItemContent {
    flex: auto;
  }

  .featuresItemImageBlock {
    flex: auto;
  }

  .featuresItemInner.reverse {
    flex-direction: column;
  }

  .featuresItemImage {
    height: 345px;
  }
}
