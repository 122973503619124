.variety {
  position: relative;
  padding: 120px 0;
  overflow: hidden;
}

.varietyContent {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  text-align: center;
}

.varietyItems {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 24px;
}
