.textLayout {
  padding: 64px 0 0;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  text-align: center;
}

.content ol {
  text-align: justify;
  padding-left: 20px;
}

.content ul {
  padding-left: 20px;
}

.text ol > li {
  color: var(--text-color-contrast-second);
  padding: 10px 0;
}

.text ol > li > p {
  padding-top: 10px;
}

.text a {
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);
  font-weight: 400;
  color: var(--text-color-contrast-second);
  font-style: normal;
}

@media (max-width: 991px) {
  .text a {
    font-size: var(--font-size-small);
    line-height: var(--line-height-small);
    font-weight: 400;
    color: var(--text-color-contrast-second);
    font-style: normal;
  }
}
