.faq {
  position: relative;
  overflow: hidden;
  padding: 120px 0;
}

.faqContent {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  text-align: center;
}

.container {
  display: flex;
  gap: 12px;
  cursor: pointer;
  margin-bottom: 8px;
}

.arrow {
  transition: all 0.4s ease;
  cursor: pointer;
}

.arrow.active {
  transform: rotate(180deg);
}

.accordion {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 0 4%;
}

.accordionContent {
  flex: 1 1;
}

.accordionCard {
  border: none;
  border-radius: 0;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-colors-second);
  padding: 20px 0 12px;
  max-width: 48%;
  width: 100%;
  height: max-content;
}

.accordionCard h6 {
  font-weight: 600;
}

.toggle {
  width: 100%;
  cursor: pointer;
}

.accordionCard p {
  text-align: left;
}

@media (max-width: 1400px) {
  .accordionCard {
    max-width: unset;
  }
}

@media (max-width: 991px) {
  .faq {
    padding: 64px 0;
  }
}
