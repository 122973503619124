.inner {
  width: 100%;
  position: relative;
}

.background {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: -1;
}

.content {
  padding: 120px 0;
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 344px;
  justify-content: center;
}

.text {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media (max-width: 991px) {
  .content {
    padding: 64px 0;
    max-width: unset;
  }
}
