.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  background: var(--color-grey-grey2-100);
  padding: 16px 0;
}

.footerContent {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  align-items: center;
}

.logoRights {
  display: flex;
  gap: 8px;
  text-align: left;
  flex-direction: column;
}

.link {
  padding: 8px 0;
}

.socialLinks {
  display: flex;
  justify-content: center;
  align-items: center;
}

.socialLinks a {
  padding: 4px 20px;
  font-size: 0;
  line-height: 0;
}

.termsPrivacy {
  display: flex;
  gap: 24px;
}

.termsPrivacy a {
  color: var(--text-color-contrast-second);
  text-align: center;
  font-size: var(--font-size-tiny);
  font-weight: 400;
  line-height: var(--line-height-tiny);
  text-decoration-line: underline;
}

@media (max-width: 991px) {
  .footerContent {
    flex-direction: column;
    gap: 24px;
  }
  .logoRights {
    text-align: center;
  }
}
