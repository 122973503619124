.gameType {
  padding: 24px;
  border-radius: var(--Border-radius-medium, 8px);
  border: 1px solid rgba(255, 255, 255, 0.15);
  box-shadow: -2px -2px 4px 0px rgba(0, 0, 0, 0.15) inset;
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 282px;
  text-align: left;
}

.gameTypeTitle {
  padding-top: 8px;
}

.gameTypeTitle p {
  font-weight: 500;
}

@media (max-width: 1400px) {
  .gameType {
    max-width: 445px;
  }
}

@media (max-width: 1200px) {
  .gameType {
    max-width: 350px;
  }
}

@media (max-width: 640px) {
  .gameType {
    max-width: unset;
  }
}
