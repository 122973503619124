.blockBackground {
  position: absolute;
  background-color: var(--fill-color-contrast);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  width: 100%;
  height: 100%;
}

.blockBackgroundImage {
  width: 200%;
  height: 200%;
}

@media (max-width: 991px) {
  .blockBackgroundImage {
    width: 300%;
    height: 100%;
  }
}
