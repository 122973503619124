@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

:root {
  --jakarta-font: 'Plus Jakarta Sans', sans-serif;

  --text-color-contrast: #fff;
  --text-color-contrast-second: #dedff4;
  --text-color-primary: #4d3ed3;
  --text-color-second: #505279;

  --color-primary-10: #ebe9f9;
  --color-primary-75: #6a5be9;
  --fill-color-contrast: #1e2049;
  --fill-color-background: #efeff8;
  --color-primary-100: #4d3ed3;
  --color-grey-50: #8c8da6;
  --color-grey-grey2-100: #0a0c36;
  --border-colors-second: #dedff4;
  --color-primary-25: #cdc9f1;

  --font-size-nano: 12px;
  --font-size-tiny: 14px;
  --font-size-small: 14px;
  --font-size-medium: 20px;
  --font-size-large: 32px;
  --font-size-huge: 48px;

  --line-height-nano: 14px;
  --line-height-tiny: 20px;
  --line-height-small: 24px;
  --line-height-medium: 32px;
  --line-height-large: 48px;
  --line-height-huge: 56px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
small {
  margin: 0;
}

body {
  margin: 0;
  font-family: var(--jakarta-font);
}

h1 {
  font-size: var(--font-size-huge);
  line-height: var(--line-height-huge);
  color: var(--text-color-contrast);
  font-weight: 600;
  font-style: normal;
}

h3 {
  font-size: var(--font-size-large);
  line-height: var(--line-height-large);
  color: var(--text-color-contrast);
  font-weight: 600;
  font-style: normal;
}

h4 {
  font-size: var(--font-size-medium);
  font-weight: 600;
  line-height: var(--line-height-medium);
  color: var(--text-color-contrast);
  font-style: normal;
}

h6 {
  font-size: var(--font-size-medium);
  font-weight: 400;
  line-height: var(--line-height-medium);
  color: var(--text-color-contrast-second);
  font-style: normal;
}

a {
  font-size: var(--font-size-tiny);
  line-height: var(--line-height-tiny);
  color: var(--text-color-contrast);
  font-weight: 600;
  font-style: normal;
}

p {
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);
  font-weight: 400;
  color: var(--text-color-contrast-second);
  font-style: normal;
  margin-bottom: 0;
}

small {
  font-size: var(--font-size-nano);
  line-height: var(--line-height-nano);
  font-weight: 400;
  color: var(--text-color-contrast-second);
  font-style: normal;
}

.container {
  padding: 0 24px;
}

button {
  text-decoration: none;
  color: var(--text-color-contrast);
  padding: 6px 12px;
  height: 32px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  width: max-content;
  transition: 0.15s all ease;
  border-radius: 8px;
  background: var(--color-primary-75);
  border: none;
}

button:hover {
  color: var(--text-color-contrast);
  background: var(--color-primary-100);
}

@media (max-width: 991px) {
  h1 {
    font-size: var(--font-size-large);
    line-height: var(--line-height-large);
  }

  h4 {
    font-size: var(--font-size-medium);
    font-weight: 600;
    line-height: var(--line-height-medium);
    color: var(--text-color-contrast);
    font-style: normal;
  }

  h6 {
    font-size: var(--font-size-small);
    line-height: var(--line-height-small);
  }

  a {
    font-size: var(--font-size-medium);
    line-height: var(--line-height-medium);
  }

  p {
    font-size: var(--font-size-small);
    line-height: var(--line-height-small);
    font-weight: 400;
    color: var(--text-color-contrast-second);
    font-style: normal;
  }
}
