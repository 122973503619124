.innerMenuList {
  display: flex;
  gap: 32px;
  margin-bottom: 0;
  list-style-type: none;
  padding: 0;
}

.itemMenuList:last-child .itemMenuLink {
  border-radius: 8px;
  background: var(--color-primary-75);
}

.itemMenuList:last-child .itemMenuLink:hover {
  color: var(--text-color-contrast);
  background: var(--color-primary-100);
}

.itemMenuLink {
  text-decoration: none;
  color: var(--text-color-contrast);
  padding: 6px 12px;
  height: 32px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  width: max-content;
  transition: 0.15s all ease;
}

.itemMenuLink:hover {
  color: var(--text-color-primary);
  background: var(--color-primary-10);
}

@media (max-width: 991px) {
  .itemMenuLink {
    padding: 14px 0;
    height: auto;
  }

  .itemMenuList:last-child .itemMenuLink {
    padding: 14px;
  }

  .itemMenuLink:hover {
    padding: 14px;
    height: auto;
  }
}
