.landing {
  position: relative;
  font-family: var(--jakarta-font);
  height: 100%;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
}

.landing > * {
  font-family: var(--poppins-font);
}

.content {
  flex: 1;
  position: relative;
  overflow: hidden;
}
