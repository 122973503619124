.innerMenu {
  display: none;
  position: fixed;
  top: -100%;
  width: 100%;
  flex-direction: column;
  margin: 0 auto;
  padding: 30px;
  background-color: var(--color-grey-grey2-100);
  transition: top 0.5s ease-in-out;
  z-index: 10;
}

.overlay {
  visibility: hidden;
  opacity: 0;
  content: '';
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.13);
  transition: opacity 0.5s;
}

.innerMenuLinks {
  gap: 24px;
  flex-direction: column;
}

.innerButtonsAuth {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 24px;
}

.openMenu {
  top: 72px;
}

@media (max-width: 991px) {
  .innerMenu {
    display: flex;
  }
  .showOverlay {
    visibility: visible;
    opacity: 1;
  }
}
