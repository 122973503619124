.downloadButtons {
  display: flex;
  gap: 24px;
}

.downloadButtons svg {
  cursor: pointer;
}

@media (max-width: 640px) {
  .downloadButtons {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
}
