.nearestEvents {
  position: relative;
  overflow: hidden;
}

.nearestEventsBackground {
  position: absolute;
  width: 150%;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
  filter: drop-shadow(5px 5px 5px #222);
  height: 100%;
}

.nearestEventsContent {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 32px;
  padding: 120px 0;
}
