.main {
  width: 100%;
  align-items: center;
  display: flex;
  padding-top: 94px;
  position: relative;
}

.mainBackground {
  position: absolute;
  top: 0;
  height: calc(100% + 73px);
  width: 100%;
  z-index: -1;
  transform: translateY(-73px);
}

.mainContent {
  display: flex;
  gap: 32px;
  justify-content: space-around;
}

.title {
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex: 1 1 0;
  width: 0;
  justify-content: center;
}

.texts {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.phones {
  flex: 1 1 0;
  width: 0;
  display: flex;
}

.phonesImage {
  height: 622px;
}

@media (max-width: 1280px) {
  .mainContent {
    flex-direction: column;
  }

  .title {
    flex: auto;
    width: 100%;
  }

  .phones {
    flex: auto;
    width: 100%;
    justify-content: center;
  }

  .phonesImage {
    height: 307px;
  }
}
