.hamburger {
  display: none;
  width: 24px;
  height: 24px;
  cursor: pointer;
  padding: 7px;
}

.line {
  height: 2px;
  width: 100%;
  background-color: var(--text-color-contrast);
  display: block;
  transition: 0.3s ease;
}

.line:nth-child(2) {
  margin: 2px 0;
}

.hamburgerActive {
  padding: 6px;
}

.hamburgerActive .line {
  height: 3px;
}

.hamburgerActive .line:nth-child(1) {
  transform: translateY(5px) rotateZ(-45deg);
}

.hamburgerActive .line:nth-child(2) {
  opacity: 0;
}

.hamburgerActive .line:nth-child(3) {
  transform: translateY(-5px) rotateZ(45deg);
}

@media (max-width: 991px) {
  .hamburger {
    display: block;
  }
}
